import { useQuery } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type BannerContext } from '@/infra/services/new-tail/model';
import { useGlobalStore } from '@/presenters/store/global';

const useBannersAds = (context: BannerContext, id: string) => {
  const {
    user: { resources },
  } = useGlobalStore((state) => state);

  const query = useQuery(
    ['banners-ads', resources?.userTrackingId, context, id],
    async () => {
      if (!resources?.userTrackingId) return;

      return Services.NewTail.getBannersAds({
        context,
        sessionId: resources?.userTrackingId,
        userId: resources?.id,
      });
    },
    {
      enabled: Boolean(resources?.userTrackingId),
    }
  );

  return query;
};

export default useBannersAds;
