import { memo } from 'react';

import { Stack, Heading } from '@backyard-ui/core';

import { type ModularContent } from '@/infra/services/modular-content/model';
import { GtmDataAttributesGenerator } from '@/infra/tracking/gtm-data-attributes';
import type { Partner } from '@/infra/tracking/gtm-data-attributes/generators/productsList';
import * as tracker from '@/infra/tracking/tracker';
import { Carousel } from '@/presenters/components/common/Carousel';
import {
  ProductThumb,
  type ProductThumbProps,
} from '@/presenters/components/common/ProductThumb';
import { useRatings } from '@/presenters/hooks/catalog';
import { useRunOnce } from '@/presenters/hooks/use-run-once';
import { useGlobalStore } from '@/presenters/store/global';

type Products = ModularContent.Products;
type RecommendedProducts = ModularContent.RecommendedProducts;

export interface ProductsCarouselProps extends Omit<Products, 'type'> {
  partnerName?: Partner;
  type: Products['type'] | RecommendedProducts['type'];
  onProductClick?: ProductThumbProps['onClick'];
}

function ProductsCarousel(props: ProductsCarouselProps) {
  const { partnerName, items, title, type, onProductClick } = props;

  const itemsIds = items.map((item) => item.id);

  const region = useGlobalStore((state) => state.user.session.region);
  const { data: ratings, isFetching: isFetchingRatings } = useRatings(itemsIds);

  useRunOnce(() => {
    tracker.onProductView({
      products: items,
      moduleType: type,
      region,
    });
  }, Boolean(items.length) && Boolean(region));

  return (
    <Stack direction="column" spacing={4}>
      <Heading size="2xl" asChild>
        <h2>{title}</h2>
      </Heading>

      <Carousel
        controls={{
          arrows: true,
        }}
        options={{
          slidesToScroll: 'auto',
          containScroll: 'trimSnaps',
          dragFree: true,
        }}
        itemScope
        itemType="https://schema.org/ItemList"
      >
        {items?.map((item, index) => {
          const rating = ratings?.data.find(
            (rating) => rating.productLm === item.id
          );

          return (
            <div
              className="w-[calc(theme('height.40')+theme('height[2.5]'))] shrink-0 lg:w-52 [&:not(:last-child)]:mr-2"
              key={item.id}
              data-testid="product-carousel-item"
              {...GtmDataAttributesGenerator.productsList({
                partnerName,
                item,
                title,
                position: index + 1,
              })}
            >
              <ProductThumb
                {...item}
                onClick={(event, product) => {
                  tracker.onProductClick({
                    product: product,
                    position: index + 1,
                    moduleTitle: title,
                  });
                  onProductClick && onProductClick(event, product);
                }}
                isFetchingRatings={isFetchingRatings}
                rating={Number(rating?.avgRating || 0)}
                totalReviewsCount={rating?.totalReviewsCount || 0}
              />
            </div>
          );
        })}
      </Carousel>
    </Stack>
  );
}

export default memo(ProductsCarousel);
