'use client';

import { useId, useMemo } from 'react';

import { Flex, Stack, type StackProps, Text } from '@backyard-ui/core';
import { useMediaQuery } from '@backyard-ui/hooks';
import { useInView } from 'react-intersection-observer';

import { type BannerContext } from '@/infra/services/new-tail/model';
import { Banners } from '@/presenters/components/modular-content/components';
import { useRunOnce } from '@/presenters/hooks/use-run-once';

import Skeleton from './Skeleton';
import useBannersAds from './useBannersAds';
import { buildModularBannersFromAd } from './utils';

export interface RetailMediaBannerProps {
  context: BannerContext;
  ui?: {
    showTitle?: boolean;
    hideTitleOnMobile?: boolean;
    direction?: StackProps['direction'];
  };
}

function RetailMediaBanner(props: Readonly<RetailMediaBannerProps>) {
  const {
    context,
    ui: { showTitle, direction, hideTitleOnMobile } = {
      showTitle: false,
      hideTitleOnMobile: false,
      direction: 'row',
    },
  } = props;

  const { ref, inView } = useInView();

  const id = useId();
  const isDesktop = useMediaQuery('md');

  const {
    data: bannersAdsData,
    isError: isBannersAdsError,
    isFetching: isFetchingBannersAds,
    isFetched: isFetchedBannersAds,
  } = useBannersAds(context, id);

  const modularBanners = buildModularBannersFromAd(bannersAdsData);

  useRunOnce(() => {
    modularBanners.forEach(({ retailMedia: { impressionUrl } }) => {
      navigator.sendBeacon(impressionUrl);
    });
  }, Boolean(modularBanners.length));

  useRunOnce(() => {
    modularBanners.forEach(({ retailMedia: { viewUrl } }) => {
      navigator.sendBeacon(viewUrl);
    });
  }, Boolean(modularBanners.length) && inView);

  const title = useMemo(() => {
    const hideOnMobile = showTitle && hideTitleOnMobile && !isDesktop;

    if (!showTitle || hideOnMobile) {
      return null;
    }

    return <Text>Publicidade</Text>;
  }, [isDesktop, hideTitleOnMobile, showTitle]);

  if (isFetchingBannersAds || !isFetchedBannersAds) {
    return <Skeleton />;
  }

  if (isBannersAdsError || !modularBanners.length) {
    return null;
  }

  return (
    <Stack spacing="4" ref={ref} direction={direction}>
      {title}
      <Flex justify="center" align="center" grow={1}>
        <div className="m-auto">
          <Banners
            {...modularBanners[0]}
            onClick={() => {
              navigator.sendBeacon(modularBanners[0].retailMedia.clickUrl);
            }}
          />
        </div>
      </Flex>
    </Stack>
  );
}

export default RetailMediaBanner;
