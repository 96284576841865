import { Skeleton as BackyardSkeleton, Flex } from '@backyard-ui/core';

function Skeleton() {
  return (
    <Flex justify="center" data-testid="skeleton">
      <BackyardSkeleton width="980px" height="120px" />
    </Flex>
  );
}

export default Skeleton;
